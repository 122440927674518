import { useCallback, useRef } from 'react'
import HomeBanner from './HomeBanner'
import HomeFAQ from './HomeFAQ'
import HomeHeader from './HomeHeader'
import HomeProjects from './HomeProjects'

export default function HomePage() {
  const terminalRef = useRef(null)

  const handleScrollToProjects = useCallback(() => {
    if (terminalRef && terminalRef.current) {
      /* @ts-ignore */
      terminalRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [terminalRef])

  return (
    <div>
      <HomeHeader handleScrollToProjects={handleScrollToProjects} />
      <HomeBanner />
      <div ref={terminalRef} id="projects">
        <HomeProjects />
      </div>
      <HomeFAQ />
    </div>
  )
}
