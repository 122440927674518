import { Delegator, Token } from 'gql'
import { ChangeEvent, useEffect, useState } from 'react'
import { truncateAddress } from 'utils'
import { formatNumber } from 'utils/numbers'
import { TableField } from './DeligationPoolsList'

const gridCn =
  'grid grid-cols-2 text-left ml:text-right ml:grid-cols-[auto,25%,27%,25%] dxs:grid-cols-[auto,18%,18%,20%] ds:grid-cols-[auto,20%,20%,20%] gap-y-[1rem] ml:gap-y-0 gap-x-[0.5rem] ml:gap-x-[0]'

type Props = {
  data: Delegator[]
  rewardTicker: Token['ticker']
  explorerUrl?: string
}
export function AllocationsSpreadsheet({
  data,
  rewardTicker,
  explorerUrl,
}: Props) {
  const [allocationsFullList, setAllocationsFullList] = useState(data)
  const [allocations, setAllocations] = useState<undefined | Delegator[]>()

  useEffect(() => {
    setAllocations(allocationsFullList.slice(0, 10))
  }, [allocationsFullList])

  const handlerSearch = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' && e.target.value === undefined) {
      setAllocationsFullList(data)
    }
    const result = data.filter(
      (item) => item.stakeKey === e.target.value || !e.target.value
      // item.stakeKey.includes(e.target.value)
    )
    setAllocationsFullList(result)
  }

  const handlerTenMore = () => {
    setAllocations([
      ...allocations!,
      ...allocationsFullList.slice(
        allocations?.length,
        allocations?.length! + 10
      ),
    ])
  }

  return (
    <>
      <div
        className="cardShadow mb-[2rem] rounded-[0.5rem] border border-white bg-blueLight p-[1.5rem]"
        // className="relative z-[10] rounded-[0.5rem] bg-blueLight bg-allocationsSpredsheetBg bg-contain bg-right-bottom bg-no-repeat py-[1.5rem]"
      >
        <div className="mb-[1rem] items-center justify-between dxs:flex">
          <div className="mb-[0.3rem] text-[1.2rem] font-light dxs:mb-0">
            Token allocations spreadsheet
          </div>
          <div className="relative">
            <input
              onChange={handlerSearch}
              className={`
              w-full rounded-[0.5rem] py-[0.35rem] pl-[0.85rem] pr-[2.5rem] text-[0.75rem] outline-none dxs:w-[25rem]
            `}
              type="text"
              placeholder="Search Address..."
            />
            <div className="absolute top-[0.55rem] right-[0.85rem] h-[0.7rem] w-[0.7rem] bg-loupe bg-cover bg-no-repeat" />
          </div>
        </div>
        <div className="mb-[1rem]">
          <div
            className={`mx-[1.25rem] hidden pb-[1rem] text-[0.75rem] text-grayText ml:grid ${gridCn}`}
          >
            <div className="text-left">Address</div>
            <div>Active stake</div>
            <div>Allocated</div>
            <div>Distributed</div>
          </div>
          {allocations?.map((item) => {
            return (
              <div
                key={item.stakeKey}
                className={`rounded-[0.3rem] py-[0.9rem] px-[1.25rem] even:bg-[#92A3BF]/10 ${gridCn}`}
              >
                <TableField
                  className="text-left"
                  titleCn="ml:hidden text-[0.7rem]"
                  valueCn="text-[0.7rem]"
                  title="Address"
                  value={
                    <a
                      href={`${explorerUrl}/stakekey/${item.stakeKey}`}
                      target="_blank"
                      className="font-normal text-blue hover:underline"
                      rel="noreferrer"
                    >
                      <div className="hidden dxs:block">{item.stakeKey}</div>
                      <div className="dxs:hidden">
                        {truncateAddress(item.stakeKey, 6)}
                      </div>
                    </a>
                  }
                />
                <TableField
                  titleCn="ml:hidden text-[0.7rem]"
                  valueCn="text-[0.7rem]"
                  title="Active stake"
                  value={`${formatNumber(item.liveStake)} ADA`}
                />
                <TableField
                  titleCn="ml:hidden text-[0.7rem]"
                  valueCn="text-[0.7rem]"
                  title="Allocated"
                  value={`${formatNumber(item.allocated)} ${rewardTicker}`}
                />
                <TableField
                  titleCn="ml:hidden text-[0.7rem]"
                  valueCn="text-[0.7rem]"
                  title="Distributed"
                  value={`${formatNumber(item.distributed)} ${rewardTicker}`}
                />
              </div>
            )
          })}
          {allocations?.length === 0 && (
            <div className="mt-[1rem] text-center text-[0.8rem]">
              No results
              {/* {allocationsFullList.length === 0
                ? 'No results'
                : 'Tokens are not yet allocated'} */}
            </div>
          )}
        </div>
      </div>
      {allocations?.length !== allocationsFullList.length && (
        <div className="text-center">
          <div
            onClick={handlerTenMore}
            className="cardShadow inline-block cursor-pointer rounded-[0.2rem] bg-white px-[2.5rem] py-[0.3rem] text-[0.8rem] uppercase hover:bg-white/70"
          >
            Load More
          </div>
        </div>
      )}
    </>
  )
}
