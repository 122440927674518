import { GraphQLClient } from 'graphql-request'
import * as Dom from 'graphql-request/dist/types.dom'
import gql from 'graphql-tag'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** An address on the blockchain */
  Address: string
  /** The `DateTime` scalar type represents a DateTime. The DateTime is serialized as an RFC 3339 quoted string */
  DateTime: string
  /** A decimal floating-point number. The Decimal value type is appropriate for financial calculations that require large numbers of significant integral and fractional digits and no round-off errors */
  Decimal: string
}

/** APY of a project at specific point in time */
export type ApyHistory = {
  __typename?: 'APYHistory'
  /** Annual Percentage Yield */
  apy: Scalars['Float']
  /** APY measurement time */
  time: Scalars['DateTime']
}

/** Delegator (identified by stake key) and his delegation state */
export type Delegator = {
  __typename?: 'Delegator'
  /** Total amount of distribution tokens allocated for an account. All allocated tokens will eventually be distributed */
  allocated: Scalars['Decimal']
  /** Total amount of tokens distributed for an account */
  distributed: Scalars['Decimal']
  /** Amount of funds delegated to a project pool */
  liveStake: Scalars['Decimal']
  /** Stake key of account that delegated funds to a project pool */
  stakeKey: Scalars['Address']
}

/** Single distribution of specific amount of tokens at specific time to current project delegators. */
export type Distribution = {
  __typename?: 'Distribution'
  /** Amount of tokens to distribute between all delegators. */
  amount: Scalars['Decimal']
  /** Time of a distribution. */
  time: Scalars['DateTime']
}

/** Links to delegation guides with different wallets */
export type Guide = {
  __typename?: 'Guide'
  /** A link to delegation guide with Daedalus wallet */
  daedalus: Scalars['String']
  /** A link to generic delegation guide */
  other: Scalars['String']
  /** A link to delegation guide with Yoroi wallet */
  yoroi: Scalars['String']
}

/** The most recent quantitative indicators of a project or a pool in the form of 'total <something>'. */
export type LiveTotals = {
  __typename?: 'LiveTotals'
  /** Total amount of ADA delegated. Each stake becomes active after two epochs after the delegation. */
  activeStakeADA: Scalars['Decimal']
  /** Amount of accounts that have an active stake. */
  delegatorsCount: Scalars['Int']
  /** Total amount of ADA delegated. Includes inactive stake. */
  liveStakeADA: Scalars['Decimal']
}

/** A staking pool allows accounts delegate ADA to itself. ISPO rewards based on delegations to project pools */
export type Pool = {
  __typename?: 'Pool'
  /** A pool address either in BECH32 or hex form */
  address: Scalars['Address']
  /** A link to a pool on the https://cardanoscan.io */
  cardanoscanLink: Scalars['String']
  /** Live total values of a pool */
  liveTotals: LiveTotals
  /** A pool name */
  name: Scalars['String']
  /** Percent of how close this pool is to a saturation point. To prevent existence of big pools, there is a limit to the size of rewards a pool can earn per epoch. If the number of ADA coins in the pool exceeds what is considered the saturation point, the rewards for the pool operator and stakers begin to diminish. Source: https://cexplorer.io/wiki/pool-saturation-what-is-this-and-how-it-works */
  saturationPercent: Scalars['Float']
}

/** Project is a set of pools. Each project holds an ISPO, during which accounts are rewarded based on their relative delegation to project pools. */
export type Project = {
  __typename?: 'Project'
  /** Annual Percentage Yield. */
  apy: Scalars['Float']
  /** Current percentage (0-100) of allocated tokens from the total. */
  completionPercent: Scalars['Float']
  /** Set of important project dates. */
  dates: ProjectDates
  /** Meta information about distribution token */
  distributionToken: Token
  /** Internal project ID. */
  id: Scalars['ID']
  /** A link to project cover image. */
  imageLink: Scalars['String']
  /** Live total values of a project */
  liveTotals: LiveTotals
  /** A project name. */
  name: Scalars['String']
  /** Short description of a project. */
  shortDescription: Scalars['String']
  /** Status of a project. It shows whether it is active or not. */
  status: ProjectStatus
  /** Total amount of token to distribute during ISPO. */
  totalAllocation: Scalars['Decimal']
}

/** Current state of the project allocation. */
export type ProjectAllocationProcess = {
  __typename?: 'ProjectAllocationProcess'
  /** Amount of tokens allocated since start of project. */
  tokensAllocated: Scalars['Decimal']
  /** Amount of tokens left to allocate before the end of project. */
  tokensLeft: Scalars['Decimal']
}

/** Important project dates. */
export type ProjectDates = {
  __typename?: 'ProjectDates'
  /** End time of token allocation. */
  endTime: Scalars['DateTime']
  /** Start time of token allocation. */
  startTime: Scalars['DateTime']
}

/** Combined delegations to all of the project pools at the end of each epoch. For the last epoch it represents live values. */
export type ProjectDelegation = {
  __typename?: 'ProjectDelegation'
  /** Total amount of accounts which delegated to project pools. */
  delegatorsCount: Scalars['Int']
  /** Time of measurement of specified values. */
  time: Scalars['DateTime']
  /** Total amount of ADA delegated to all project pools. */
  totalDelegatedADA: Scalars['Decimal']
}

/** Additional properties of the project. */
export type ProjectDetails = {
  __typename?: 'ProjectDetails'
  /** A link to a project pitch deck for investors. */
  deckLink?: Maybe<Scalars['String']>
  /** A link to more detailed description of the project. */
  learnMoreLink?: Maybe<Scalars['String']>
  /** Main (long) description of a project. */
  mainDescription: Scalars['String']
  /** Block of links to social networks of a project. */
  socials: ProjectSocials
  /** A link to a project website. */
  websiteLink?: Maybe<Scalars['String']>
}

/** Links to the project in the media space. */
export type ProjectSocials = {
  __typename?: 'ProjectSocials'
  /** A link to the discord account of a project. */
  discord?: Maybe<Scalars['String']>
  /** A link to the medium account of a project. */
  medium?: Maybe<Scalars['String']>
  /** A link to the reddit account of a project. */
  reddit?: Maybe<Scalars['String']>
  /** A link to the telegram account of a project. */
  telegram?: Maybe<Scalars['String']>
  /** A link to the twitter account of a project. */
  twitter?: Maybe<Scalars['String']>
}

/** Status of a project. */
export enum ProjectStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Finished = 'FINISHED',
  Ongoing = 'ONGOING',
}

/** Detailed information of a project, which includes all delegators, allocation schedule, and extended meta-information. */
export type ProjectWithDetails = {
  __typename?: 'ProjectWithDetails'
  /** Allocation process state. */
  allocationState: ProjectAllocationProcess
  /** Historical data of project's APY. */
  apyHistory: Array<ApyHistory>
  /** History of project delegations to all of its pools combined. */
  delegationHistory: Array<ProjectDelegation>
  /** Current allocation and distribution state per each delegator of the project. */
  delegators: Array<Delegator>
  /** Detailed meta-information about a project. */
  details: ProjectDetails
  /** Distribution schedule of previously allocated tokens between delegators. Also known as vesting. */
  distributionSchedule: Array<Distribution>
  /** Meta information about distribution token */
  distributionToken: Token
  /** Internal project ID. */
  id: Scalars['ID']
  /** A link to project cover image. */
  imageLink: Scalars['String']
  /** Live total values of a project */
  liveTotals: LiveTotals
  /** A project name. */
  name: Scalars['String']
  /** Set of project pools. Project can consist of more that one pool due to limitation in max stake 60'000'000 ADA per pool. */
  pools: Array<Pool>
  /** Short description of a project. */
  shortDescription: Scalars['String']
  /** Status of a project. It shows whether it is active or not. */
  status: ProjectStatus
}

export type RootQuery = {
  __typename?: 'RootQuery'
  /** Query delegation guide links */
  guide: Guide
  /** Query single project */
  project: ProjectWithDetails
  /** Query all projects */
  projects: Array<Project>
}

export type RootQueryProjectArgs = {
  id: Scalars['ID']
}

/** A crypto asset that represents some virtual currency */
export type Token = {
  __typename?: 'Token'
  /** Address of a token */
  address: Scalars['Address']
  /** Number of digits after decimal place of a token */
  decimals: Scalars['Int']
  /** A human-readable description of a token */
  description?: Maybe<Scalars['String']>
  /** A base64-encoded image of a token */
  logo?: Maybe<Scalars['String']>
  /** Full name of a token */
  name: Scalars['String']
  /** Short name of a token */
  ticker?: Maybe<Scalars['String']>
  /** A web page relating to the token */
  url?: Maybe<Scalars['String']>
}

export type LiveTotalsFragment = {
  __typename?: 'LiveTotals'
  activeStakeADA: string
  liveStakeADA: string
  delegatorsCount: number
}

export type TokenFragment = {
  __typename?: 'Token'
  address: string
  decimals: number
  description?: string | null
  logo?: string | null
  name: string
  ticker?: string | null
  url?: string | null
}

export type ProjectAndGuideQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type ProjectAndGuideQuery = {
  __typename?: 'RootQuery'
  project: {
    __typename?: 'ProjectWithDetails'
    id: string
    imageLink: string
    shortDescription: string
    name: string
    allocationState: {
      __typename?: 'ProjectAllocationProcess'
      tokensAllocated: string
      tokensLeft: string
    }
    apyHistory: Array<{ __typename?: 'APYHistory'; apy: number; time: string }>
    delegators: Array<{
      __typename?: 'Delegator'
      allocated: string
      distributed: string
      liveStake: string
      stakeKey: string
    }>
    delegationHistory: Array<{
      __typename?: 'ProjectDelegation'
      delegatorsCount: number
      time: string
      totalDelegatedADA: string
    }>
    details: {
      __typename?: 'ProjectDetails'
      mainDescription: string
      deckLink?: string | null
      learnMoreLink?: string | null
      websiteLink?: string | null
      socials: {
        __typename?: 'ProjectSocials'
        discord?: string | null
        medium?: string | null
        reddit?: string | null
        telegram?: string | null
        twitter?: string | null
      }
    }
    distributionSchedule: Array<{
      __typename?: 'Distribution'
      amount: string
      time: string
    }>
    distributionToken: {
      __typename?: 'Token'
      address: string
      decimals: number
      description?: string | null
      logo?: string | null
      name: string
      ticker?: string | null
      url?: string | null
    }
    pools: Array<{
      __typename?: 'Pool'
      address: string
      cardanoscanLink: string
      saturationPercent: number
      name: string
      liveTotals: {
        __typename?: 'LiveTotals'
        activeStakeADA: string
        liveStakeADA: string
        delegatorsCount: number
      }
    }>
  }
  guide: {
    __typename?: 'Guide'
    daedalus: string
    yoroi: string
    other: string
  }
}

export type ProjectsQueryVariables = Exact<{ [key: string]: never }>

export type ProjectsQuery = {
  __typename?: 'RootQuery'
  projects: Array<{
    __typename?: 'Project'
    apy: number
    completionPercent: number
    id: string
    imageLink: string
    name: string
    shortDescription: string
    status: ProjectStatus
    totalAllocation: string
    dates: { __typename?: 'ProjectDates'; endTime: string; startTime: string }
    distributionToken: {
      __typename?: 'Token'
      address: string
      decimals: number
      description?: string | null
      logo?: string | null
      name: string
      ticker?: string | null
      url?: string | null
    }
    liveTotals: {
      __typename?: 'LiveTotals'
      activeStakeADA: string
      liveStakeADA: string
      delegatorsCount: number
    }
  }>
}

export const LiveTotalsFragmentDoc = gql`
  fragment liveTotals on LiveTotals {
    activeStakeADA
    liveStakeADA
    delegatorsCount
  }
`
export const TokenFragmentDoc = gql`
  fragment token on Token {
    address
    decimals
    description
    logo
    name
    ticker
    url
  }
`
export const ProjectAndGuideDocument = gql`
  query ProjectAndGuide($id: ID!) {
    project(id: $id) {
      allocationState {
        tokensAllocated
        tokensLeft
      }
      apyHistory {
        apy
        time
      }
      delegators {
        allocated
        distributed
        liveStake
        stakeKey
      }
      delegationHistory {
        delegatorsCount
        time
        totalDelegatedADA
      }
      details {
        mainDescription
        deckLink
        learnMoreLink
        socials {
          discord
          medium
          reddit
          telegram
          twitter
        }
        websiteLink
      }
      distributionSchedule {
        amount
        time
      }
      distributionToken {
        ...token
      }
      id
      imageLink
      shortDescription
      name
      pools {
        address
        cardanoscanLink
        liveTotals {
          ...liveTotals
        }
        saturationPercent
        name
      }
    }
    guide {
      daedalus
      yoroi
      other
    }
  }
  ${TokenFragmentDoc}
  ${LiveTotalsFragmentDoc}
`
export const ProjectsDocument = gql`
  query Projects {
    projects {
      apy
      completionPercent
      dates {
        endTime
        startTime
      }
      id
      imageLink
      name
      shortDescription
      status
      totalAllocation
      distributionToken {
        ...token
      }
      liveTotals {
        ...liveTotals
      }
    }
  }
  ${TokenFragmentDoc}
  ${LiveTotalsFragmentDoc}
`

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string
) => Promise<T>

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType
) => action()

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper
) {
  return {
    ProjectAndGuide(
      variables: ProjectAndGuideQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<ProjectAndGuideQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ProjectAndGuideQuery>(
            ProjectAndGuideDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'ProjectAndGuide',
        'query'
      )
    },
    Projects(
      variables?: ProjectsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<ProjectsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ProjectsQuery>(ProjectsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Projects',
        'query'
      )
    },
  }
}
export type Sdk = ReturnType<typeof getSdk>
