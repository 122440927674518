import React from 'react'
import { ReactComponent as TwitterIcon } from 'resources/images/socials/twitter.svg'
import { ReactComponent as InstagramIcon } from 'resources/images/socials/instagramm.svg'
import { ReactComponent as RedditIcon } from 'resources/images/socials/reddit.svg'
import { ReactComponent as TelegramIcon } from 'resources/images/socials/telegram.svg'
import { ReactComponent as MediumIcon } from 'resources/images/socials/medium.svg'
import { ReactComponent as DiscordIcon } from 'resources/images/socials/discord.svg'

export type SocialLinkIconType =
  | 'telegram'
  | 'medium'
  | 'twitter'
  | 'github'
  | 'discord'
  | 'whitepaper'
  | 'reddit'
  | 'instargam'
  | string

export type SocialLinkProps = {
  url?: string | null
  // name?: string
  icon?: SocialLinkIconType
  className?: string
  wrapClassName?: string
}

export default function SocialLink({
  url,
  // name,
  icon = 'telegram',
  className,
  wrapClassName,
}: SocialLinkProps) {
  const iconComp = React.useMemo(() => {
    switch (icon) {
      case 'telegram':
        return <TelegramIcon />
      case 'medium':
        return <MediumIcon />
      case 'twitter':
        return <TwitterIcon />
      // case 'github':
      //   return <GithubIcon />
      case 'discord':
        return <DiscordIcon />
      // case 'whitepaper':
      //   return <WhitepaperIcon />
      case 'reddit':
        return <RedditIcon />
      case 'instagram':
        return <InstagramIcon />
    }
  }, [icon])

  if (!url) {
    return null
  }
  const handleClick = () => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  return (
    <div onClick={handleClick} className={`cursor-pointer ${wrapClassName}`}>
      {iconComp
        ? React.cloneElement(iconComp, {
            className: `fill-current w-[0.8rem] h-[0.8rem] ${className}`,
          })
        : null}
    </div>
  )
}
