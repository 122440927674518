import { maxBy } from 'lodash'
import { abbrNum } from 'utils/abbrNum'
import { formatDate } from 'utils/dayjs'
import { toDecimal } from 'utils/numbers'
import { DistributionScheduleProps } from '.'

export default function DistributionScheduleDesktop({
  data,
  ticker,
}: DistributionScheduleProps) {
  const itemWidth = `calc(100%/${data.length})`
  const maxItem = maxBy(data, (item) => Number(item.amount)) || { amount: '0' }

  return (
    <div className="relative h-[7rem]">
      <div className="flex h-full">
        <div className="flex h-full w-full space-x-[4px]">
          {data.map((item, i) => {
            const heightPercent = toDecimal(item.amount)
              .div(toDecimal(maxItem.amount))
              .mul(100)

            const unlocked = Date.parse(item.time) < Date.now()
            return (
              <div
                key={i}
                style={{ width: itemWidth }}
                className="flex-end flex h-full flex-col justify-end"
              >
                <div className="flex-end relative flex h-full w-full flex-col justify-end rounded-lg">
                  <div
                    style={{ height: `${heightPercent.toString()}%` }}
                    className={`
                    relative rounded-[0.5rem] 
                    ${
                      unlocked
                        ? 'border border-grayText/60 opacity-60'
                        : 'bg-[#EBEEF3]'
                    } 
                    `}
                  >
                    <div className="absolute bottom-[0.3rem] left-[0.6rem] text-[0.75rem] text-blue">
                      {abbrNum(Number(item.amount), 2)}
                      <br />
                      {ticker}
                    </div>
                  </div>
                </div>
                <div className="flex h-[2rem] items-center justify-center text-center text-[0.7rem] text-grayText">
                  {formatDate(item.time, false, 'MM.YY')}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
