import { maxBy } from 'lodash'
import { abbrNum } from 'utils/abbrNum'
import { formatDate } from 'utils/dayjs'
import { toDecimal } from 'utils/numbers'
import { DistributionScheduleProps } from '.'

export default function DistributionScheduleMobile({
  data,
  ticker,
}: DistributionScheduleProps) {
  const maxItem = maxBy(data, (item) => Number(item.amount)) || { amount: '0' }

  return (
    <div className="relative w-full">
      <div className="w-full">
        <div className="flex w-full flex-col space-y-[0.25rem]">
          {data.map((item, i) => {
            const widthPercent = toDecimal(item.amount)
              .div(toDecimal(maxItem.amount))
              .mul(100)
            const unlocked = Date.parse(item.time) < Date.now()

            return (
              <div
                key={i}
                style={{ width: `${widthPercent.toString()}%` }}
                className={`
                relative flex h-[1.5rem] w-full items-center justify-between rounded-[0.5rem] px-[0.5rem]
                    ${
                      unlocked
                        ? 'border border-grayText/60 opacity-60'
                        : 'bg-[#EBEEF3]'
                    } 
                    `}
              >
                <div className="text-[0.6rem] text-blue">
                  {abbrNum(Number(item.amount), 2)} {ticker}
                </div>
                <div className="text-[0.6rem] text-grayText">
                  {formatDate(item.time, false, 'MM.YY')}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
