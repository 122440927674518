import { useStore } from 'effector-react'
import { ProjectAndGuideQuery } from 'gql'
import { graphqlSdk } from 'gql/client'
import { $projects } from 'models/app'
import { useEffect, useState } from 'react'
import {
  // Navigate,
  useParams,
} from 'react-router-dom'
import { AllocationsSpreadsheet } from './AllocationsSpreadsheet'
import Charts from './Charts'
import DeligationPoolsList from './DeligationPoolsList'
import { DetailsDatesAndProgress } from './DetailsDatesAndProgress'
import DetailsHeader from './DetailsHeader'
import DistributionSchedule from './DistributionSchedule'
import { ReactComponent as PreloaderIcon } from 'resources/images/preloader.svg'

export default function ProjectDetailsPage() {
  const { id: selectedProjectId } = useParams()

  const projectFromProjects = useStore($projects).find(
    (project) => project.id === selectedProjectId
  )

  const [projectDetailsAndGuid, setProjectDetailsAndGuid] =
    useState<ProjectAndGuideQuery>()

  useEffect(() => {
    async function fetch() {
      if (!projectFromProjects) return
      const projectDetailsAndGuid = await graphqlSdk.ProjectAndGuide({
        id: selectedProjectId!,
      })
      setProjectDetailsAndGuid(projectDetailsAndGuid)
    }
    fetch()
    const fetchInterval = setInterval(fetch, 10000)

    return function cleanup() {
      clearInterval(fetchInterval)
    }
  }, [selectedProjectId, projectFromProjects])

  // if (!projectFromProjects) {
  //   return <Navigate to="/" replace={true} />
  // }

  if (!projectDetailsAndGuid)
    return (
      <PreloaderIcon className="fixed left-1/2 top-1/2 h-[10rem] w-[10rem] -translate-x-1/2 -translate-y-1/2" />
    )

  const {
    completionPercent,
    apy,
    liveTotals,
    dates: { startTime, endTime },
    status,
  } = projectFromProjects!

  const {
    project: {
      apyHistory,
      name,
      imageLink,
      details: { mainDescription, socials, websiteLink, learnMoreLink },
      delegationHistory,
      distributionSchedule,
      distributionToken: { ticker },
      pools,
      delegators,
    },
    guide,
  } = projectDetailsAndGuid!
  return (
    <>
      <div className="mt-[5rem] dxs:mt-[5.5rem] dm:mt-[6.9rem] dl:mt-[7.35rem] dxl:mt-[9.05rem]">
        <DetailsHeader
          name={name}
          imageLink={imageLink}
          mainDescription={mainDescription}
          learnMoreLink={learnMoreLink}
          socials={socials}
          websiteLink={websiteLink}
          status={status}
        />
        <div className="cardShadow mb-[2.8rem] rounded-[0.5rem] border border-white bg-blueLight p-[1.5rem]">
          <DetailsDatesAndProgress
            start={startTime}
            finish={endTime}
            percent={completionPercent}
          />
          <Charts
            apyHistory={apyHistory}
            apy={apy}
            delegationHistory={delegationHistory}
            liveTotals={liveTotals}
          />
          <div className="my-[1.5rem] h-[0.1rem] w-full bg-grayProgressBg" />
          <DistributionSchedule data={distributionSchedule} ticker={ticker} />
        </div>
        <DeligationPoolsList guide={guide} pools={pools} />
        <AllocationsSpreadsheet
          data={delegators}
          rewardTicker={ticker}
          explorerUrl={'https://cardanoscan.io'}
        />
      </div>
      <div className="mt-[2rem] text-center text-[0.75rem] text-grayText">
        *Please note that the minimum amount to receive a reward is equivalent
        to 5 ADA
      </div>
    </>
  )
}
