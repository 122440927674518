import Button from 'components/Button'
import { Link } from 'react-router-dom'
import { ReactComponent as ChakraIcon } from 'resources/images/icons/chakra.svg'
import { ReactComponent as PoolsIcon } from 'resources/images/icons/pools.svg'
import { ReactComponent as HomeCircle } from 'resources/images/home-circle.svg'
import { ReactComponent as ByOccamDaoIcon } from 'resources/images/icons/by-occam-dao.svg'

type Props = {
  handleScrollToProjects: () => void
}
export default function HomeHeader({ handleScrollToProjects }: Props) {
  const highlightedPoolId = process.env.REACT_APP_HIGHLIGHTED_POOL_ID ?? ''

  return (
    <div
      className={`
      relative
      pt-[3rem] pb-[3rem] 
      ml:pt-[3.85rem] ml:pb-[6rem] 
      dxs:pt-[5.8rem] dxs:pb-[10rem]
      ds:pt-[7.7rem] ds:pb-[13.2rem]
      dm:pt-[8rem] dm:pb-[15rem]
      dl:pt-[10.4rem] dl:pb-[17.1rem] 
      dxl:pt-[15rem]
    `}
    >
      <HomeCircle className="absolute bottom-[-42rem] left-1/2 hidden h-[48.15rem] w-[48.15rem] -translate-x-1/2 dxs:block" />
      <div
        className={`
        text-center text-[2rem] font-thin tracking-[-0.02em]
        mm:text-[2.4rem] dxs:text-[2.7rem] ds:text-[3.6rem] dm:text-[4.2rem] dl:text-[4.9rem]
      `}
      >
        Cardano’s prime <br className="ml:hidden" />{' '}
        <span className="relative font-normal">
          ISPO platform{' '}
          <ByOccamDaoIcon className="absolute right-[-1rem] top-[-3rem] hidden h-[2.15rem] w-[6.5rem] t:block" />
        </span>
      </div>
      <div
        className="
        mt-[2.44rem] flex flex-col items-center justify-center space-y-[1.1rem]
        ml:flex-row ml:space-x-[1.75rem] ml:space-y-0 dxs:mt-[3rem]
      "
      >
        <Link to={`/project/${highlightedPoolId}`} className="w-full ml:w-auto">
          <Button className="h-[3rem] w-full justify-between hover:bg-[#F2EEE6] ml:w-[13rem]">
            <span>
              Farm <span className="ml-[0.3rem] font-medium">$CHAKRA</span>
            </span>
            <ChakraIcon className="h-[1.55rem] w-[1.55rem]" />
          </Button>
        </Link>
        <Button
          onClick={handleScrollToProjects}
          type="grOpacity"
          className="group h-[3rem] w-full justify-between ml:w-[13rem]"
        >
          <span>See our Pools</span>
          <PoolsIcon className=" h-[0.65rem] w-[1rem] fill-white transition-colors group-hover:fill-black" />
        </Button>
      </div>
    </div>
  )
}
