import { createEffect, createEvent, restore } from 'effector'
import { ProjectsQuery } from 'gql'
import { graphqlSdk } from 'gql/client'

export const setProjects = createEvent<ProjectsQuery['projects']>()

export const $projects = restore(setProjects, [])

export const fetchProjectsFx = createEffect(async () => {
  try {
    const { projects } = await graphqlSdk.Projects()
    setProjects(projects)
  } catch (err) {
    // todo: report error
  }
})
