import { Guide, Pool } from 'gql'
import { ReactNode } from 'react'
import { formatNumber } from 'utils/numbers'

type Props = {
  guide: Guide
  pools: Pool[]
}
export default function DeligationPoolsList({
  guide: { daedalus, yoroi, other },
  pools,
}: Props) {
  const otherLink =
    'https://hackmd.io/@OccamDAO/ISPO#How-to-participate-in-an-ISPO'

  return (
    <div className="cardShadow mb-[2.8rem] rounded-[0.5rem] border border-white bg-blueLight p-[1.5rem]">
      <div className="mb-[1.25rem] flex flex-col items-center justify-between ml:flex-row">
        <div className="mb-[0.5rem] text-[1.2rem] font-light ml:mb-0">
          Delegation pools list
        </div>
        <div className="flex flex-col items-center ml:items-end dxs:flex-row dxs:space-x-[1.25rem]">
          {/* <Link href={daedalus} text="How to delegate with Daedalus?" />
          <Link href={yoroi} text="How to delegate with Yoroi?" /> */}
          <Link href={otherLink} text="How to delegate?" />
          {/* Other Ways to delegate */}
        </div>
      </div>
      <div className="flex flex-col space-y-[0.5rem]">
        {pools.map((pool) => {
          return (
            <a
              href={pool.cardanoscanLink}
              target="_blank"
              rel="noreferrer"
              key={pool.address}
            >
              <div className="hover:deligationPoolBgHover relative rounded-[0.5rem] border border-[#E8E9EB] px-[1rem] py-[0.6rem]">
                <div className="grid grid-cols-1 gap-y-[1rem] pb-[3.4rem] mm:pb-0 ml:grid-cols-2 dxs:grid-cols-[20%,17%,17%,15%,14%]">
                  <TableField title="Pool Name" value={pool.name} />
                  <TableField
                    title="Live stake"
                    value={`${formatNumber(pool.liveTotals.liveStakeADA)} ADA`}
                  />
                  <TableField
                    title="Active stake"
                    value={`${formatNumber(
                      pool.liveTotals.activeStakeADA
                    )} ADA`}
                  />
                  <TableField
                    title="Saturation"
                    value={`${formatNumber(pool.saturationPercent)}%`}
                  />
                  <TableField
                    title="Participants"
                    value={formatNumber(pool.liveTotals.delegatorsCount)}
                  />
                </div>
                <TableField
                  className="absolute bottom-[0.6rem] text-left mm:right-[1rem] mm:top-[0.6rem]  mm:bottom-auto mm:text-right ml:bottom-[0.6rem] ml:top-auto dxs:bottom-auto dxs:top-[0.6rem]"
                  title="More on Cardanoscan"
                  value={
                    <span className="text-[0.8rem] text-blue hover:underline">
                      Learn More
                    </span>
                  }
                />
              </div>
            </a>
          )
        })}
      </div>
    </div>
  )
}

type PoolFieldType = {
  title: string
  value: string | ReactNode
  className?: string
  titleCn?: string
  valueCn?: string
}

export function TableField({
  title,
  value,
  className,
  titleCn,
  valueCn,
}: PoolFieldType) {
  return (
    <div className={className}>
      <div className={`mb-[0.15rem] text-[0.7rem] text-grayText ${titleCn}`}>
        {title}
      </div>
      <div className={`text-[0.7rem] font-medium ${valueCn}`}>{value}</div>
    </div>
  )
}

type LinkType = {
  href: string
  text: string
}

function Link({ href, text }: LinkType) {
  return (
    <a
      href={href}
      target="_blank"
      className="text-[0.8rem] text-blue hover:underline"
      rel="noreferrer"
    >
      {text}
    </a>
  )
}
