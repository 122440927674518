import { ReactComponent as CancelledIcon } from 'resources/images/icons/cancelled.svg'
import { ReactComponent as FinishedIcon } from 'resources/images/icons/finished.svg'
import { ReactComponent as ActiveIcon } from 'resources/images/icons/active.svg'
import { ProjectStatus } from 'gql'

type Props = {
  status: ProjectStatus
  className?: string
  withoutIcon?: boolean
}

type StatusMapType = {
  [key in string]: {
    icon: JSX.Element
    cn: string
  }
}

const STATUS_MAP: StatusMapType = {
  CANCELLED: {
    icon: <CancelledIcon />,
    cn: 'bg-tacao',
  },
  FINISHED: {
    icon: <FinishedIcon />,
    cn: 'bg-coolGrey',
  },
  ACTIVE: {
    icon: <ActiveIcon />,
    cn: 'bg-pastelBlue',
  },
}

export function PoolStatus({ status, className, withoutIcon = false }: Props) {
  if (status === ProjectStatus.Ongoing) return null

  const currentStatus = STATUS_MAP[status]
  return (
    <div
      className={`
        flex h-[1.7rem] w-[6.8rem] items-center justify-center rounded-[0.21rem] text-[0.7rem]
        ${currentStatus.cn}
        ${className}
      `}
    >
      {!withoutIcon && (
        <span className="relative top-[-0.07rem] mr-[0.35rem]">
          {currentStatus.icon}
        </span>
      )}

      <span className="uppercase text-white">{status}</span>
    </div>
  )
}
