import Button from 'components/Button'
import { PoolStatus } from 'components/PoolStatus'
import { ProgressLine } from 'components/ProgressLine'
import { useStore } from 'effector-react'
import { Project } from 'gql'
import { $projects } from 'models/app'
import { Link } from 'react-router-dom'
import { formatNumber } from 'utils/numbers'

export default function HomeProjects() {
  const projects = useStore($projects)
  projects.sort(
    (a, b) => Date.parse(b.dates.endTime) - Date.parse(a.dates.endTime)
  )

  return (
    <>
      <div className="mb-[0.8rem] items-center justify-between t:flex">
        <div
          className="
            text-[0.66rem] 
            dxs:text-[0.77rem] ds:text-[0.8rem] dm:text-[0.9rem] dl:text-[1.05rem]
          "
        >
          <span className="font-semibold">
            Delegate with confidence to any of the incredible projects
            conducting their ISPO with the Occam DAO.
          </span>{' '}
          Simply click on their respective card to see valuable statistics and
          information. You are just one click away from pre-money exposure to
          some of the most innovative projects in web3.
        </div>
        <div
          className="
            ml-0 mt-[1.1rem] flex text-[3rem] tracking-[-0.02em]
            t:ml-[2rem] t:mt-0
            dxs:ml-[4.4rem] dxs:text-[4.5rem] 
            ds:text-[4.66rem] 
            dl:text-[4.9rem]
          "
        >
          ISPO
          <span className="ml-[0.7rem] font-light">pools</span>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-[1.25rem] dxs:grid-cols-2">
        {projects.map((project) => {
          return <Card key={project.id} {...project} />
        })}
        {projects.length % 2 !== 0 && (
          <div className="cardShadow hidden rounded-[0.5rem] border border-white/50 bg-graySecond/50 dxs:block" />
        )}
      </div>
    </>
  )
}

function Card({
  id,
  apy,
  name,
  liveTotals: { delegatorsCount, activeStakeADA },
  totalAllocation,
  completionPercent,
  distributionToken,
  imageLink,
  shortDescription,
  status,
}: Project) {
  return (
    <Link to={`/project/${id}`}>
      <div className="cardShadow  rounded-[0.5rem] border border-white bg-graySecond py-[1.5rem] px-[1.25rem] transition-transform hover:translate-y-[-0.15rem]">
        <div className="mb-[1rem] justify-between ml:flex ml:space-x-0">
          <div className="mb-[1rem] t:mr-[0.5rem]">
            <div className="mb-[0.5rem] text-[1.2rem] leading-[140%] ml:mb-[0.2rem]">
              {name}
            </div>
            <div className="text-[0.75rem] leading-[140%] text-grayText t:max-h-[1rem]">
              {shortDescription}
            </div>
          </div>

          <Button
            type="blueBorder"
            className="h-[2.75rem] w-[10rem] text-[0.8rem] font-medium"
          >
            Learn More
          </Button>
        </div>
        <div className="mb-[1rem] flex flex-col ml:flex-row ml:justify-between ml:space-x-[1.25rem] dxs:space-x-[1.05rem] ds:space-x-[1.25rem]">
          {/* img start */}
          <div
            className="
              relative mb-[1rem] h-[6.5rem] w-full 
              ml:h-[10rem] ml:w-[10rem]
              t:mb-[0] 
              dxs:h-[7.5rem] dxs:w-[7.5rem] 
              dm:h-[10rem] dm:w-[10rem]
            "
          >
            <PoolStatus
              status={status}
              className="
                absolute top-[-0.5rem] right-[-0.5rem] z-20 
                ml:left-[-0.5rem] ml:top-[0.5rem]
              "
            />
            <div className="relative h-full w-full overflow-hidden rounded-[5px]">
              <div
                className="absolute z-0 h-full w-full bg-cover bg-center bg-no-repeat"
                style={{ backgroundImage: `url(${imageLink})` }}
              />
              <div
                className="
              absolute bottom-[0.35rem] right-[0.35rem] z-[10]
              ml:right-1/2 ml:translate-x-1/2
            "
              >
                <div
                  className="
                  flex h-[2.15rem] items-center rounded-[0.25rem] bg-white/75 
                  px-[0.5rem]
                  dxs:h-[1.8rem] dxs:text-[1.2rem] dm:h-[2.15rem] dm:text-[1.3rem]
                "
                >
                  <div className="mr-[0.4rem] font-light">APY</div>
                  <div className="font-medium">{formatNumber(apy)}%</div>
                </div>
              </div>
            </div>
          </div>
          {/* img end */}
          <div className="flex grow flex-col justify-between">
            <div className="flex justify-between space-x-[0.5rem]">
              <div>
                <div className="text-[0.75rem] leading-[115%] text-grayText">
                  Delegators
                </div>
                <div className="text-[0.9rem]">
                  {formatNumber(delegatorsCount)}
                </div>
              </div>
              <div className="text-right">
                <div className="text-[0.75rem] leading-[115%] text-grayText">
                  Active stake
                </div>
                <div className="text-[0.9rem]">
                  {formatNumber(activeStakeADA)} ADA
                </div>
              </div>
            </div>
            <div className="my-[0.65rem] h-[0.05rem] w-full bg-black opacity-10" />
            <div className="mb-[0.6rem] flex items-center justify-between">
              <div className="text-[0.75rem] leading-[115%] text-grayText">
                Token Allocation
              </div>
              <div className="text-right text-[0.9rem]">
                {formatNumber(totalAllocation)} {distributionToken.ticker}
              </div>
            </div>
            <div className="">
              <div className="mb-[0.6rem] flex items-center justify-between">
                <div className="text-[0.75rem] text-grayText">
                  Completion status
                </div>
                <div className="text-[0.9rem]">
                  {formatNumber(completionPercent, { fractionDigits: 2 })}%
                </div>
              </div>
              <ProgressLine percent={completionPercent.toString()} />
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}
