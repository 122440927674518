import { useState } from 'react'
// import { ReactComponent as LogoIcon } from 'resources/images/logo.svg'
// import { ReactComponent as LogoTextIcon } from 'resources/images/logo-text.svg'
// import { ReactComponent as BurgerIcon } from 'resources/images/burger.svg'
import { ReactComponent as LogoAndTextIcon } from 'resources/images/icons/logo-and-text.svg'
import { ReactComponent as MenuIcon } from 'resources/images/icons/menu.svg'
import { ReactComponent as ApplyIspoIcon } from 'resources/images/icons/apply-ispo.svg'

import { LAYOUT_STYLES } from 'App'
import { Link } from 'react-router-dom'
import Sidebar from './Sidebar'
import SocialLink from 'components/SocialLink'
import { SOCIAL_LINKS_HEADER } from 'utils/links'

export default function Header() {
  const [active, setActive] = useState(false)

  return (
    <>
      <div
        className={`
          ${LAYOUT_STYLES} relative
          py-[2.4rem] dxs:py-[3.4rem]
        `}
      >
        <div className="flex items-center justify-between">
          <Link to={{ pathname: '/' }}>
            <LogoAndTextIcon className="h-[1.2rem] w-[8.95rem]" />
          </Link>
          <div className="flex items-center space-x-[2rem] dxs:space-x-[4.5rem]">
            <div className="hidden items-center space-x-[0.4rem] t:flex">
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSfC5z1gjvvpKwahIIT-NvBD6E_w4eMZkwJSraFkU7AzyBhr2Q/viewform"
                className="group mr-[2rem] hidden items-center text-[0.9rem] font-medium dxs:flex"
                target="_blank"
                rel="noreferrer"
              >
                <ApplyIspoIcon className="mr-[0.55rem] h-[1.05rem] w-[1.05rem] stroke-[#505D6D] group-hover:stroke-veryBlue" />
                <span className="text-[#3A4350] group-hover:text-veryBlue">
                  Apply for ISPO
                </span>
              </a>
              {SOCIAL_LINKS_HEADER.map((item, i) => (
                <SocialLink
                  key={`social-links-${i}`}
                  icon={item.name}
                  url={item.link}
                  className="h-[0.9rem] w-[0.9rem] fill-[#3A4350]/70 group-hover:fill-veryBlue"
                  wrapClassName="
                    group flex items-center justify-center w-[2.4rem] h-[2.4rem]
                    bg-[#76AAE7]/0 border-[#76AAE7]/0
                    hover:bg-white hover:border-[#76AAE7]/10 hover:bg-[#76AAE7]/30
                    rounded-full transition-opacity
                  "
                />
              ))}
            </div>
            <MenuIcon
              className="h-[1.3rem] w-[3.25rem] cursor-pointer fill-[#505D6D] transition-colors hover:fill-veryBlue"
              onClick={() => setActive(true)}
            />
          </div>
        </div>
      </div>
      <Sidebar isOpen={active} setIsOpen={setActive} />
    </>
  )
}
