import Button from 'components/Button'
import { Distribution, Token } from 'gql'
import DistributionScheduleDesktop from './DistributionScheduleDesktop'
import DistributionScheduleMobile from './DistributionScheduleMobile'
import { ReactComponent as TosidropIcon } from 'resources/images/icons/tosidrop.svg'

export type DistributionScheduleProps = {
  data: Distribution[]
  ticker: Token['ticker']
}
export default function DistributionSchedule({
  data,
  ticker,
}: DistributionScheduleProps) {
  const unlocked = Date.parse(data[0].time) < Date.now()

  const handlerClaimLink = () => {
    window.open('https://app.tosidrop.io/cardano/claim', '_blank')
  }
  return (
    <div className="relative z-10 pb-[0.5rem]">
      <div className="mb-[0.55rem] items-center justify-between space-x-0 mm:flex mm:space-x-[1rem]">
        <div className="mb-[0.2rem] text-[1.2rem] font-light mm:mb-0">
          Token distribution schedule
        </div>
        <div className="flex items-center">
          <div className="mr-[0.5rem] h-[1rem] w-[1rem] rounded-[0.1rem] bg-[#EBEEF3]" />
          <div className="text-[0.75rem] text-grayText">
            Reward claiming unlock
          </div>
        </div>
      </div>
      <div className="hidden dxs:block">
        <DistributionScheduleDesktop data={data} ticker={ticker} />
      </div>
      <div className="dxs:hidden">
        <DistributionScheduleMobile data={data} ticker={ticker} />
      </div>
      {unlocked && (
        <div className="mt-[1rem] flex items-center justify-end ">
          <Button
            onClick={handlerClaimLink}
            className="group rounded-[0.25rem] bg-blue text-white hover:bg-white hover:text-blue dxs:mb-0"
          >
            Claim on TosiDrop{' '}
            <TosidropIcon className="ml-[0.5rem] h-[0.9rem] w-[0.9rem] fill-white opacity-50 transition-colors group-hover:fill-blue" />
          </Button>
        </div>
      )}
    </div>
  )
}
