import { ApyHistory, LiveTotals, Project, ProjectDelegation } from 'gql'
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { abbrNum } from 'utils/abbrNum'
import { formatDate } from 'utils/dayjs'
import { formatNumber } from 'utils/numbers'

type Props = {
  apy: Project['apy']
  apyHistory: ApyHistory[]
  delegationHistory: ProjectDelegation[]
  liveTotals: LiveTotals
}
export default function Charts({
  apyHistory,
  apy,
  delegationHistory,
  liveTotals: { delegatorsCount, activeStakeADA },
}: Props) {
  const apyHistoryChartData = apyHistory.map((item) => {
    return {
      xTitle: formatDate(item.time, true),
      point: item.apy,
    }
  })

  const totalADADelegatedData = delegationHistory.map((item) => {
    return {
      xTitle: formatDate(item.time, true),
      point: Number(item.totalDelegatedADA),
    }
  })

  const totalParticipantsCount = delegationHistory.map((item) => {
    return {
      xTitle: formatDate(item.time, true),
      point: Number(item.delegatorsCount),
    }
  })

  return (
    <div className="relative z-0 grid grid-rows-[10rem,10rem,10rem] gap-[1rem] t:grid-cols-2 dxs:grid-cols-3 dxs:grid-rows-[10rem]">
      <div className="grid grid-rows-[2.5rem,7.5rem] t:col-span-2 dxs:col-span-1">
        <div className="mb-[1rem] flex items-center justify-between px-[1rem] pt-[1.5rem] leading-[140%]">
          <div className="text-[0.75rem] text-grayText">
            Annual Percentage Yield
          </div>
          <div className="text-[1.8rem] text-blue">{formatNumber(apy)}%</div>
        </div>
        <div className="h-full w-full">
          <Chart data={apyHistoryChartData} type="apy" />
        </div>
      </div>
      <div className="grid grid-rows-[2.5rem,auto] t:col-span-2 dxs:col-span-1">
        <div className="mb-[1rem] flex items-center justify-between px-[1rem] pt-[1.5rem] leading-[140%]">
          <div className="text-[0.75rem] text-grayText">Active stake</div>
          <div className="text-[0.9rem] text-blue">
            {formatNumber(activeStakeADA)} ADA
          </div>
        </div>
        <div className="h-full w-full">
          <Chart data={totalADADelegatedData} type="tad" />
        </div>
      </div>
      <div className="grid grid-rows-[2.5rem,auto] t:col-span-2 dxs:col-span-1">
        <div className="mb-[1rem] flex items-center justify-between px-[1rem] pt-[1.5rem] leading-[140%]">
          <div className="text-[0.75rem] text-grayText">Delegators*</div>
          <div className="text-[0.9rem] text-blue">
            {formatNumber(delegatorsCount)}
          </div>
        </div>
        <div className="h-full w-full">
          <Chart data={totalParticipantsCount} />
        </div>
      </div>
    </div>
  )
}

type ChartProps = {
  data: { xTitle: string; point: number }[]
  type?: 'apy' | 'tad'
}
function Chart({ data, type }: ChartProps) {
  return (
    <ResponsiveContainer width="99%" height="100%">
      <AreaChart
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: -10,
          bottom: 0,
        }}
      >
        <CartesianGrid
          strokeDasharray="3 0"
          vertical={false}
          stroke="#D9DCE1"
          horizontalPoints={[20, 40, 60, 80]}
        />
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#5094FC" stopOpacity={0.11} />
            <stop offset="95%" stopColor="#5094FC" stopOpacity={0.03} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="xTitle"
          tick={{
            fontSize: 12,
            fill: '#939BA9',
            dx: 0,
            fontFamily: 'NeueHaasDisplay',
          }}
          tickLine={false}
          stroke="#D9DCE1"
          tickSize={4}
          interval="preserveStartEnd"
          minTickGap={15}
          // padding={{ left: 12 }}
        />
        <YAxis
          tickLine={false}
          tickFormatter={(value, index) => {
            if (type === 'apy') {
              return `${value}%`
            }
            if (type === 'tad') {
              return abbrNum(value, 0).toString()
            }
            return abbrNum(value, 1).toString()
          }}
          tick={{
            fontSize: 12,
            fill: '#939BA9',
            dy: -5,
            fontFamily: 'NeueHaasDisplay',
          }}
          stroke="#F4F5F7"
          interval="preserveStartEnd"
          padding={{ top: 20 }}
          minTickGap={2}
          // tickCount={5}
        />
        <Tooltip content={<CustomTooltip type={type} />} />
        <Area
          type="monotone"
          dataKey="point"
          dot={false}
          stroke="#5094FC"
          strokeWidth={2}
          fillOpacity={1}
          fill="url(#colorUv)"
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

const CustomTooltip = ({ active, payload, label, type }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="!rounded-[0.2rem] border !border-[#DEE0E5] bg-blueLight p-[0.6rem] !outline-none">
        <div className="font-neue text-[0.6rem] text-grayText">{label}</div>
        <div className="font-neue text-[0.75rem] text-blue">
          {type === 'apy'
            ? `${formatNumber(payload[0].value)}%`
            : type === 'tad'
            ? `${formatNumber(payload[0].value)} ADA`
            : formatNumber(payload[0].value)}
        </div>
      </div>
    )
  }

  return null
}
