import { ProgressLine } from 'components/ProgressLine'
import { formatDate } from 'utils/dayjs'
import { formatNumber } from 'utils/numbers'

type Props = {
  start: string
  finish: string
  percent: number
}
export function DetailsDatesAndProgress({ start, finish, percent }: Props) {
  return (
    <div className="mb-[1.6rem]">
      <div className="mb-[0.5rem] flex items-center">
        <div>
          <div className="text-[0.75rem] text-grayText">ISPO Start</div>
          <div className="text-[0.9rem]">
            {formatDate(start, false, 'MMM DD, YYYY')}
          </div>
        </div>
        <div className="mx-[1.65rem]">
          <div className="text-[0.75rem] text-grayText">Completion status</div>
          <div className="text-[0.9rem] text-blue">
            {formatNumber(percent, { fractionDigits: 2 })}%
          </div>
        </div>
        <div className="ml-auto text-right">
          <div className="text-[0.75rem] text-grayText">ISPO Finish</div>
          <div className="text-[0.9rem]">
            {formatDate(finish, false, 'MMM DD, YYYY')}
          </div>
        </div>
      </div>
      <ProgressLine percent={percent.toString()} />
    </div>
  )
}
