import { ReactNode } from 'react'

type Props = {
  children: ReactNode | string
  type?: 'blueBorder' | 'grOpacity' | 'gray'
  className?: string
  onClick?: () => void
}

export default function Button({ children, type, className, onClick }: Props) {
  let cn = ''
  switch (type) {
    case 'blueBorder':
      cn =
        'border-[0.1rem] border-[#5094FC]/50 text-black/75 hover:text-black/100'
      break
    case 'grOpacity':
      cn =
        'grButtonStyles border-[0.1rem] border-white text-white hover:text-black'
      break
    case 'gray':
      cn = 'bg-[#F2EEE6] text-black'
      break
    default:
      cn = 'defaultButtonStyles bg-white'
      break
  }
  return (
    <div
      className={`
      ${cn} 
      flex h-[2.35rem] cursor-pointer items-center justify-center  rounded-[0.5rem]
      px-[1rem] text-center text-[0.9rem] transition-colors duration-300 hover:bg-white
      ${className}
      `}
      onClick={onClick}
    >
      {children}
    </div>
  )
}
