import { PoolStatus } from 'components/PoolStatus'
import SocialLink from 'components/SocialLink'
import { ProjectSocials, ProjectStatus } from 'gql'
import { HashLink as Link } from 'react-router-hash-link'
import { ReactComponent as ArrowBackIcon } from 'resources/images/icons/arrow-back.svg'

type Props = {
  imageLink: string
  name: string
  mainDescription: string
  learnMoreLink?: string | null
  websiteLink?: string | null
  deckLink?: string | null
  socials: ProjectSocials
  status: ProjectStatus
}

export default function DetailsHeader({
  imageLink,
  name,
  mainDescription,
  learnMoreLink,
  socials,
  websiteLink,
  deckLink,
  status,
}: Props) {
  return (
    <div
      className="
            relative
            mb-[2.75rem] gap-x-[1.3rem] 
            ml:grid-flow-col-dense 
            t:mb-[5.75rem] t:grid t:grid-cols-[11rem] t:grid-rows-[2.5rem,auto,2.5rem] t:gap-x-[2.5rem] dxs:mb-[2.75rem]
            dxs:grid-cols-[15.75rem] dxs:grid-rows-[4.2rem,auto,2.5rem]          
          "
    >
      <Link to="/#projects" className="absolute top-[-4rem] right-0">
        <div className="flex cursor-pointer items-center bg-white/100 px-[0.7rem] py-[0.1rem] hover:bg-white/70">
          <ArrowBackIcon className="mr-[0.4rem] h-[0.25rem] w-[0.55rem]" />
          <span className="cardShadow text-[0.8rem]">Back to main page</span>
        </div>
      </Link>
      <div
        className="cardShadow
              z-0 row-span-3 mb-[1.5rem] h-[9rem] w-full
              overflow-hidden rounded-[0.5rem] border-[3px] border-white bg-cover 
              bg-center bg-no-repeat
              t:mb-0 t:h-[11rem] t:w-[11rem]
              dxs:h-[15.75rem] dxs:w-[15.75rem]
            "
        style={{ backgroundImage: `url(${imageLink})` }}
      >
        <PoolStatus
          withoutIcon
          status={status}
          className="
            absolute right-[-0.5rem] top-[0.8rem] z-20 
            h-[2rem] w-[8rem]
            !text-[0.8rem] t:left-[-0.5rem]
          "
        />
        <div className="cardImgBg z-[10] h-full w-full" />
      </div>
      <div className="mb-[0.6rem] text-[1.5rem] dxs:text-[2.75rem]">{name}</div>
      <div className="col-span-2 mt-[1rem] text-[0.8rem] font-light leading-[140%] ml:col-span-1 ml:mt-0 dxs:text-[0.95rem] ds:text-[1.05rem]">
        {mainDescription}
        {learnMoreLink && (
          <a
            href={learnMoreLink}
            target="_blank"
            rel="noreferrer"
            className="mt-[0.5rem] block cursor-pointer text-blue underline underline-offset-2 hover:no-underline"
          >
            Learn more
          </a>
        )}
      </div>
      <div className="col-span-2 mt-[1rem] flex flex-wrap ml:col-span-1 dxs:mt-0">
        {!!websiteLink && (
          <a
            href={websiteLink}
            target="_blank"
            className="
              transiton-color mr-[0.6rem] mb-[0.6rem] flex h-[2.5rem] cursor-pointer
              items-center rounded-[0.25rem] bg-blue px-[2.25rem] text-[0.8rem]
              text-white hover:bg-white hover:text-blue dxs:mb-0
            "
            rel="noreferrer"
          >
            WEBSITE
          </a>
        )}
        {!!deckLink && (
          <a
            href={deckLink}
            target="_blank"
            className="
                transiton-color mr-[0.6rem] flex h-[2.5rem] cursor-pointer items-center
                justify-center rounded-[0.25rem] bg-white/70 px-[1.5rem] text-[0.8rem] text-blue
                hover:bg-white dxs:justify-between
              "
            rel="noreferrer"
          >
            DECK
          </a>
        )}

        {Object.keys(socials).map((name) => {
          if (name === '__typename') return null
          return (
            <SocialLink
              key={`social-links-${name}`}
              icon={name}
              //@ts-ignore
              url={socials[name]}
              className="!w-[1rem]  fill-blue"
              wrapClassName="
                    flex items-center justify-center w-[2.5rem] h-[2.5rem] mr-[0.6rem]
                    bg-white/70 hover:bg-white rounded-[0.25rem] transition-opacity
                  "
            />
          )
        })}
      </div>
    </div>
  )
}
