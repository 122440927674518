type ProgressLineProps = {
  percent: string
}
export function ProgressLine({ percent }: ProgressLineProps) {
  return (
    <div className="relative h-[0.25rem] w-full rounded-[0.5rem] bg-grayProgressBg">
      <div
        className="absolute left-0 h-full rounded-[0.5rem] bg-blue"
        style={{ width: `${percent}%` }}
      ></div>
    </div>
  )
}
