import Marquee from 'react-fast-marquee'
import { ReactComponent as CardanoLogo } from 'resources/images/partners/logo/cardano.svg'
import { ReactComponent as CfundLogo } from 'resources/images/partners/logo/cfund.svg'
import { ReactComponent as Coin360Logo } from 'resources/images/partners/logo/coin360.svg'
import { ReactComponent as CvvcLogo } from 'resources/images/partners/logo/cvvc.svg'
import { ReactComponent as EmurgoLogo } from 'resources/images/partners/logo/emurgo.svg'
import { ReactComponent as ScalableLogo } from 'resources/images/partners/logo/scalable.svg'

const PARTNERS = [
  {
    link: 'https://emurgo.io/',
    logo: <EmurgoLogo fill="#3A4144" />,
  },
  {
    link: 'https://cfund.vc/',
    logo: <CfundLogo fill="#3A4144" />,
  },
  {
    logo: <CvvcLogo fill="#3A4144" />,
    link: 'https://cvvc.com/',
  },
  {
    logo: <Coin360Logo fill="#3A4144" />,
    link: 'https://coin360.com/',
  },
  {
    logo: <CardanoLogo fill="#3A4144" />,
    link: 'https://cardano.org/',
  },
  {
    logo: <ScalableLogo fill="#3A4144" />,
    link: 'https://scalablesolutions.io/',
  },
]

export default function Partners() {
  return (
    <div className="relative z-20 my-[5.85rem]">
      {/* <div className={`${LAYOUT_STYLES} mb-[1.3rem]`}>
        <div className="text-[2.75rem] font-thin">The Partners</div>
      </div> */}

      <Marquee play={true} pauseOnHover={true} gradient={false}>
        {PARTNERS.map((partner, i) => {
          return (
            <a
              className="mx-[3.75rem]"
              key={`partner-${i}`}
              href={partner.link}
              target="_blank"
              rel="noreferrer"
            >
              {partner.logo}
            </a>
          )
        })}
      </Marquee>
    </div>
  )
}
