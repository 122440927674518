import Footer from 'components/Footer'
import Header from 'components/Header'
import HomePage from 'components/HomePage'
import ProjectDetailsPage from 'components/ProjectDetailsPage'
import ScrollToTop from 'components/ScrollToTop'

import { Navigate, RouteObject, useLocation, useRoutes } from 'react-router-dom'

const singlePoolMode = process.env.REACT_APP_SINGLE_POOL_ADDRESS

const routes: RouteObject[] = [
  {
    path: '/',
    element: singlePoolMode ? <div></div> : <HomePage />,
  },
  {
    path: 'project/:id',
    element: <ProjectDetailsPage />,
  },
].concat({
  path: '*',
  element: <Navigate to="/" replace={true} />,
})

export const LAYOUT_STYLES =
  'relative z-10 m-auto w-[285px] grow items-start mm:w-[90%] ml:w-[520px] t:w-[75%] dxs:w-[900px] dm:w-[1200px] dl:w-[1388px] dxl:w-[1400px]'
export const layoutCn =
  'rounded-[0.7rem] bg-white py-[1.5rem] px-[1rem] ml:py-[2.25rem] ml:px-[2.05rem]'

export default function App() {
  const element = useRoutes(routes)
  const location = useLocation()
  const isHome = location.pathname === '/'
  const isDetails = location.pathname.startsWith('/project/')

  return (
    <div
      className={`
        bg-contain bg-top bg-no-repeat
        ${isHome ? 'bg-homeBg dxl:bg-homeBg4k' : ''}
        ${isDetails ? 'bg-detailsBg' : ''}
      `}
    >
      <ScrollToTop />
      <Header />
      <div className={`${LAYOUT_STYLES} min-h-[100vh]`}>{element}</div>
      <Footer />
    </div>
  )
}
