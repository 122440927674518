import SocialLink from 'components/SocialLink'
import { ReactComponent as MenuCrossIcon } from 'resources/images/icons/menu-cross.svg'

import {
  APPLY_IDO_LINK,
  FORUM_LINK,
  CAREERS_LINK,
  OCCAMX_LINK,
  OCCAMX_DASHBOARD_LINK,
  IDO_KYC,
  USING_IDO_KYC,
  VC_DAO,
  SOCIAL_LINKS,
} from 'utils/links'

type Props = {
  isOpen: boolean
  setIsOpen: (a: boolean) => void
}

export default function Sidebar({ isOpen, setIsOpen }: Props) {
  const linksCn = 'transition-colors hover:text-veryBlue'
  return (
    <div
      className={`
      fixed top-0 z-20 h-full w-full overflow-hidden rounded-l-[0.5rem]
      bg-white/80 backdrop-blur-[5px] transition-[right] duration-500 ease-in-out
      t:w-[34.1rem]
        ${
          isOpen
            ? 'right-[0%]'
            : 'right-[-100%] ml:right-[-150%] t:right-[-100%]'
        } 
    `}
    >
      <MenuCrossIcon
        className="
          absolute top-[3rem] right-[3rem] z-50 h-[1.3rem] w-[1.3rem] cursor-pointer
          stroke-[#646464] transition-colors hover:stroke-veryBlue dxs:right-[12rem]
        "
        onClick={() => setIsOpen(false)}
      />
      <div
        className={`
          relative z-40 flex h-full flex-col justify-between
          pl-[1.3rem] pt-[5.5rem]
          mm:pl-[1.8rem]
          ml:pl-[2.5rem]
          t:pl-[5.4rem]
          t:pt-[5.5rem] t:pr-[9.15rem]
        `}
      >
        <div
          className="
            grid grid-cols-1 gap-[0.9rem] text-left text-[1.2rem] font-light text-black 
            transition-colors
          "
        >
          <a
            href={OCCAMX_LINK}
            target="_blank"
            className={linksCn}
            rel="noreferrer"
          >
            OccamX
          </a>
          <a
            href={OCCAMX_DASHBOARD_LINK}
            target="_blank"
            className={linksCn}
            rel="noreferrer"
          >
            OccamX Dashboard
          </a>
          {/* <Link to={'/ecosystem'}>ECOSYSTEM</Link> */}
          {/* <Link to={"/team"}>TEAM</Link> */}
          <a
            href={APPLY_IDO_LINK}
            target="_blank"
            className={linksCn}
            rel="noreferrer"
          >
            Apply for IDO
          </a>
          <a
            href={CAREERS_LINK}
            target="_blank"
            className={linksCn}
            rel="noreferrer"
          >
            Careers
          </a>
          <a
            href={IDO_KYC}
            target="_blank"
            className={linksCn}
            rel="noreferrer"
          >
            IDO KYC
          </a>
          <a
            href={USING_IDO_KYC}
            target="_blank"
            className={linksCn}
            rel="noreferrer"
          >
            Accredited U.S. KYC
          </a>
          {/*<Link to={"/swap"}>ETH {"<>"} ADA BRIDGE</Link>*/}
          <a href={VC_DAO} target="_blank" className={linksCn} rel="noreferrer">
            VC DAO
          </a>
          <a
            href={FORUM_LINK}
            target="_blank"
            className={linksCn}
            rel="noreferrer"
          >
            Forum
          </a>
        </div>
        <div
          className="
            mb-[0.6rem] w-[13rem] border-t border-grayText/40 pt-[1.5rem]
            mm:mb-[1.5rem] 
            t:mb-[4rem] t:flex t:w-[15rem] t:items-center t:space-x-[1.5rem] 
            dxs:mb-[6.5rem] dm:mb-[7rem] dl:mb-[7.8rem]
          "
        >
          <div className="grid w-[10rem] grid-cols-5 gap-[1rem] t:w-[7rem] t:grid-cols-3">
            {SOCIAL_LINKS.map((item, i) => {
              return (
                <SocialLink
                  key={`social-links-${i}`}
                  icon={item.name}
                  url={item.link}
                  className="h-[0.9rem] w-[0.9rem] fill-grayText/60"
                />
              )
            })}
          </div>
          <div
            className="
              mt-[0.75rem] text-[1.2rem] font-thin leading-[120%] tracking-[-0.02em] text-grayText 
              t:mt-0  t:w-[9.35rem]
            "
          >
            Be a part of our community
          </div>
        </div>
      </div>
      {/* 
      <div className="absolute top-0 right-0 z-0 flex h-full w-full flex-col">
        <div className="flex  justify-end">
          <div className="hidden h-[154px] w-full bg-sidebarBgTop bg-center bg-no-repeat ml:block dxl:h-[200px] dxl:bg-cover"></div>
        </div>
        <div className="flex flex-[1] justify-end">
          <div className="h-full w-[708px] bg-sidebarBgMiddle opacity-95 dxl:w-[964px]"></div>
        </div>
        <div className="flex  justify-end">
          <div className="hidden h-[306px] w-[708px] bg-sidebarBgBottom bg-center bg-no-repeat  ml:block dxl:h-[350px] dxl:w-[966px] dxl:bg-cover"></div>
        </div>
      </div> 
      */}
    </div>
  )
}
