export const END_TIMER_DATE = new Date(2021, 4, 14, 18, 30, 0)
export const APPLY_IDO_LINK = 'https://forms.gle/tztXqpjcSWmS9TjE6'
export const FAQ_LINK =
  'https://occam-fi.gitbook.io/occam-faq/-MY6AK8LDbVHXnVAsex9'
export const RAZER_APP_LINK = 'https://razer.occam.fi/'
export const OCCAMX_APP_LINK = 'https://app.occam-x.fi/'
export const RAZER_STAKE_LINK =
  'https://razer.occam.fi/personal/occ-panel/staking'
export const CAREERS_LINK = 'https://angel.co/company/occam-fi/jobs'
export const ADA_STAKE_POOL_LINK =
  'https://adapools.org/pool/34f513d0f993d188557696d598f54944879c24cb60811aea840fce47'
export const ADA_STAKE_POOL_BASE_LINK = 'https://adapools.org/pool/'
export const FORUM_LINK = 'https://forum.occam.fi/'
export const OCCAMX_LINK = 'https://occam-x.fi'
export const INCUBATOR_LINK = 'https://occam.fi/incubator'
export const OCCAMX_DASHBOARD_LINK = 'https://occam-x.fi/ocx_board'
export const INCUBATOR_DECK = 'https://docsend.com/view/nfnc4sumeptuevry'
export const MAIL_LINK = 'mailto:alikhan@occam.fi'
export const SUPPORT_LINK = 'https://support.occam.fi/support/tickets/new'
export const IDO_KYC = 'https://occam.fi/ido-kyc'
export const USING_IDO_KYC = 'https://occam.fi/usinst-ido-kyc'
export const VC_DAO = 'https://occam.fi/vcdao'

export const SOCIAL_LINKS = [
  {
    name: 'twitter',
    link: 'https://twitter.com/OccamFi',
  },
  // {
  //   name: 'instagram',
  //   link: 'https://www.instagram.com/occamfi',
  // },
  {
    name: 'reddit',
    link: 'https://www.reddit.com/user/OccamFi',
  },
  {
    name: 'telegram',
    link: 'https://t.me/occamfi_com',
  },
  {
    name: 'medium',
    link: 'https://medium.com/occam-finance',
  },
  {
    name: 'discord',
    link: 'https://t.co/pAtpzrAkH0',
  },
]

export const SOCIAL_LINKS_HEADER = [
  {
    name: 'twitter',
    link: 'https://twitter.com/OccamFi',
  },
  // {
  //   name: 'instagram',
  //   link: 'https://www.instagram.com/occamfi',
  // },
  {
    name: 'reddit',
    link: 'https://www.reddit.com/user/OccamFi',
  },
  {
    name: 'telegram',
    link: 'https://t.me/occamfi_com',
  },
  {
    name: 'medium',
    link: 'https://medium.com/occam-finance',
  },
  {
    name: 'discord',
    link: 'https://t.co/pAtpzrAkH0',
  },
]
