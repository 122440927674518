import { layoutCn } from 'App'
import Button from 'components/Button'
import { Link } from 'react-router-dom'
import { ReactComponent as PoolsIcon } from 'resources/images/icons/pools.svg'
import mainBannerBg from 'resources/images/main-banner-bg.png'

export default function HomeBanner() {
  const highlightedPoolId = process.env.REACT_APP_HIGHLIGHTED_POOL_ID ?? ''

  return (
    <div
      className={`${layoutCn} !dxs:pt-[3.75rem] relative mb-[7.4rem] rounded-[0.4rem] !px-0 !pt-[2rem] !pb-0`}
    >
      <div className="mb-[0.6rem] text-center text-[2rem] font-thin uppercase t:mb-[0.9rem] dxs:text-[2.9rem]">
        Farm $CHAKRA
      </div>
      <div
        className={`
        mx-auto w-full px-[1rem] text-center tracking-[0.01em] t:px-[2rem]
        dxs:w-[40rem] dxs:p-0
      `}
      >
        <p className="mb-[0.9rem] text-[0.7rem] leading-[140%] dxs:text-[1.05rem]">
          $CHAKRA is built from the ground up to allow our DAO members exposure
          to a constant stream of innovation, and you can now continuously farm
          $CHAKRA via a dedicated ISPO.
        </p>
        <p
          className={`
          mb-[-2rem] text-[0.6rem] font-light opacity-60 t:mb-[-4rem]
          dxs:mb-[-1.2rem]
          dxs:text-[0.8rem]
        `}
        >
          Being one of the first tokens to utilize our new ISPO infrastructure,
          you will gain access to a highly diverse, interchain portfolio of
          project tokens - all by simply delegating your $ADA to the correct
          stake pool. Head on over to our ISPO dashboard to begin your staking
          journey.
        </p>
      </div>
      <div
        className={`
          relative top-[4rem] w-full
          t:top-[6.25rem] t:px-[0.5rem] 
          dxs:top-[4rem] dxs:px-[5rem]
         
        `}
      >
        <img src={mainBannerBg} alt="" />
        <Link to={`/project/${highlightedPoolId}`}>
          <Button
            type="gray"
            className="
          group absolute left-1/2 bottom-[1rem] h-[3rem] w-[11rem] -translate-x-1/2 justify-between
          mm:bottom-[2rem] ml:bottom-[3.5rem] t:bottom-[4rem]
        "
          >
            <span className="font-medium">PARTICIPATE</span>
            <PoolsIcon className=" h-[0.65rem] w-[1rem] fill-black transition-colors" />
          </Button>
        </Link>
      </div>
    </div>
  )
}
