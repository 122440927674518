import Button from 'components/Button'
import { ReactComponent as OccamIcon } from 'resources/images/icons/occam.svg'
import { ReactComponent as IspoIcon } from 'resources/images/icons/ispo.svg'
import { ReactNode, useState } from 'react'
import { ReactComponent as ChevronUpIcon } from 'resources/images/icons/chevron-up.svg'

const buttonCn =
  'w-full ml:w-[9rem] dm:w-[10.5rem] text-blue h-[3rem] dxs:h-[2.5rem] dm:h-[3.05rem] border-[#5094FC]/25'

export default function HomeFAQ() {
  const [currentTab, setCurrentTab] = useState('ispo')

  let currentFAQ: FAQMapT

  switch (currentTab) {
    case 'ispo':
      currentFAQ = FAQ_ISPO_MAP
      break
    case 'chakra':
      currentFAQ = FAQ_CHAKRA_MAP
      break
    default:
      currentFAQ = FAQ_ISPO_MAP
      break
  }

  return (
    <div className="relative">
      <div
        className="
          absolute left-[1rem] top-[4.5rem] z-0 h-[42.25rem] w-[15.2rem] 
          bg-faqRockBg bg-contain bg-no-repeat
          mm:w-[17rem]
          ml:top-[2rem] ml:w-[27.2rem]
          dm:top-[4rem] dm:left-[4rem]
        "
      />
      <div
        className="
      relative mt-[6.55rem] 
      dxs:grid dxs:grid-cols-[1fr,22.3rem] dxs:gap-x-[2.4rem]
      dm:grid-cols-[1fr,25.88rem] dm:gap-x-[3.4rem]
      dl:grid-cols-[1fr,27.25rem]
    "
      >
        <div className="mb-[1.3rem] dxs:mb-0">
          <div className="mb-[1.2rem] items-center justify-between ml:mb-0 ml:flex">
            <div className="mb-[1.2rem] text-[3.6rem] ml:mb-0 dm:text-[5rem]">
              FAQ
            </div>
            <div className="space-y-[1rem] ml:flex ml:space-y-0 ml:space-x-[0.45rem]">
              <Button
                type="blueBorder"
                className={`${buttonCn} ${
                  currentTab === 'ispo' ? '!bg-white' : ''
                } `}
                onClick={() => {
                  setCurrentTab('ispo')
                }}
              >
                Occam ISPO
              </Button>
              <Button
                type="blueBorder"
                className={`${buttonCn} ${
                  currentTab === 'chakra' ? '!bg-white' : ''
                } `}
                onClick={() => {
                  setCurrentTab('chakra')
                }}
              >
                $CHAKRA token
              </Button>
            </div>
          </div>
          <div className="space-y-[1.15rem]">
            {currentFAQ?.map((item, i) => {
              return (
                <ItemFAQ
                  key={`${i}-item-${currentTab}`}
                  title={item.title}
                  content={item.content}
                />
              )
            })}
          </div>
        </div>
        <div
          className="
          relative h-[18.6rem] w-full rounded-[0.25rem] bg-faqBannerBg bg-cover
          bg-center bg-no-repeat
          ml:h-[27rem]
          dxs:h-[28.6rem] dxs:w-[22.3rem]
          dm:h-[33.1rem] dm:w-[25.88rem] 
          dl:h-[34.65rem] dl:w-[27.25rem]
        "
        >
          <OccamIcon
            className="
            absolute top-[1rem] right-[1rem] h-[1.2rem] w-[1.8rem]
            ml:right-[1.9rem] ml:top-[1.9rem]

          "
          />
          <div
            className="
            absolute left-[1rem] bottom-[1rem] flex items-center
            ml:left-[2.35rem] ml:bottom-[1.85rem]
          "
          >
            <IspoIcon className="h-[1.275rem] w-[5.5rem]" />
            <div className="ml-[0.45rem] text-[0.55rem] text-[#27303B]/30">
              Also, more chains like BNB
              <br />
              Chain, Avalanche and Polygon
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

type ItemFAQProps = {
  title: string
  content: ReactNode | string
  open?: boolean
}
function ItemFAQ({ title, content, open = false }: ItemFAQProps) {
  const [isOpen, setIsOpen] = useState(open)

  const handleOpen = () => {
    setIsOpen(!isOpen)
  }
  return (
    <div
      className={`
        cardShadow  overflow-hidden rounded-[0.5rem] border border-white 
         bg-graySecond px-[1.3rem] transition-[max-height] duration-300
      `}
    >
      <div
        className="group grid cursor-pointer grid-cols-[1fr,1.95rem] items-center justify-between gap-x-[1rem]"
        onClick={handleOpen}
      >
        <div className="py-[1rem] text-[1rem] dm:text-[1.2rem] dl:text-[1.3rem]">
          {title}
        </div>
        <div className="relative h-[1.95rem] w-[1.95rem] cursor-pointer rounded-[0.25rem] bg-[#D2DCE4]/30 transition-opacity group-hover:bg-[#D2DCE4]/50">
          <ChevronUpIcon
            className={`
              absolute top-1/2 left-1/2 h-[0.45rem]
              w-[0.8rem] -translate-x-1/2 -translate-y-1/2 transition-transform
              ${isOpen ? 'rotate-0' : 'rotate-180'}
            `}
          />
        </div>
      </div>
      <div
        className={`text-[0.9rem] ${
          isOpen ? 'max-h-[100rem] pb-[1.25rem]' : 'max-h-[0rem]'
        }`}
      >
        {content}
      </div>
    </div>
  )
}

type FAQMapT = { title: string; content: ReactNode | string }[]

const FAQ_ISPO_MAP: FAQMapT = [
  {
    title: 'What is an ISPO?',
    content: (
      <>
        <p className="mb-[1.2rem]">
          ISPO stands for Initial Stake Pool Offering. It is an innovative way
          to fund new cryptocurrency projects while maintaining the
          decentralized and functioning Proof of Stake (PoS) consensus of the
          blockchain.
        </p>
        <p>
          Would you like to know more?{' '}
          <a
            href="https://hackmd.io/@OccamDAO/ISPO"
            target="_blank"
            className="cursor-pointer font-medium text-blue hover:underline"
            rel="noreferrer"
          >
            Read the Occam ISPO guide here.
          </a>
        </p>
      </>
    ),
  },
  {
    title: 'What is a stake pool?',
    content: (
      <p>
        <b>Think of a stake pool as a validation node.</b> Stake pool is a
        server node that focuses on maintenance and holds the combined stake
        (“delegation”) of various stakeholders in a single entity. Stake pools
        are responsible for processing transactions and producing new blocks and
        are at the core of Ouroboros, the Cardano proof-of-stake protocol.
      </p>
    ),
  },
  {
    title: 'How to participate in an ISPO?',
    content: (
      <p>
        Participating in an ISPO is quite straightforward. To take part in an
        ISPO, navigate to the chosen ISPO page and delegate to the appropriate
        stake pool. Participation does not require any kind of KYC - the only
        information you reveal is your stake and wallet address.
      </p>
    ),
  },
  {
    title: 'Are the coins locked?',
    content: (
      <p>
        <b>No!</b> If you decide to stake your ADA, they will never leave your
        wallet. You can stake as much as you have since you can transfer, sell
        or exchange your ADA at any time.
      </p>
    ),
  },
  {
    title: 'How does my project apply for an ISPO with the Occam DAO?',
    content: (
      <>
        <p>
          To apply for an ISPO, simply fill in this{' '}
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSfC5z1gjvvpKwahIIT-NvBD6E_w4eMZkwJSraFkU7AzyBhr2Q/viewform"
            target="_blank"
            className="cursor-pointer font-medium text-blue hover:underline"
            rel="noreferrer"
          >
            form
          </a>{' '}
          with your project’s details and our contributors will respond as soon
          as possible.
        </p>
      </>
    ),
  },
]

const FAQ_CHAKRA_MAP: FAQMapT = [
  {
    title: 'What exactly is the $CHAKRA token?',
    content: (
      <>
        <p className="mb-[1.2rem]">
          The $CHAKRA token represents a weighted basket of all the incubated
          project tokens deposited in a tokenized pool. It will be tradable in
          secondary markets, and its value would be exposed to the value of each
          token deposited in the tokenized pool.
        </p>
        <p>
          Would you like to know more?{' '}
          <a
            href="/"
            className="cursor-pointer font-medium text-blue hover:underline"
          >
            Read the Chakra Token guide here.
          </a>
        </p>
      </>
    ),
  },
  {
    title: 'What is this tokenized liquidity pool?',
    content: (
      <p>
        The Occam DAO requires all incubated projects to contribute to the
        Chakra Pool. Some projects that will only hold an IDO on our platform
        will have the opportunity to contribute, while other projects joining
        the Occam DAO as a Guild will also contribute to the pool.
      </p>
    ),
  },
  {
    title: 'What about CED rewards?',
    content: (
      <p>
        $CHAKRA tokens will replace the former CED rewards system. With CHAKRA,
        there is no need to physically obtain each project token to get exposure
        to them and we can thus provide more value to Occam DAO members. Users
        will no longer need to claim for each project token or sell them
        individually on secondary markets.
      </p>
    ),
  },
  {
    title: 'How can users get a share of the tokenized $CHAKRA pool?',
    content: (
      <p>
        Users will be able to burn $CHAKRA in return for a pro-rata share of the
        tokens sitting at the $CHAKRA Pool. The percentage of project tokens the
        user will receive equals the percentage of $CHAKRA they are burning,
        relative to the circulating supply of $CHAKRA.
      </p>
    ),
  },
]
