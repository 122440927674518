import { LAYOUT_STYLES } from 'App'
import SocialLink from 'components/SocialLink'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as OccamFooterrIcon } from 'resources/images/icons/occam-footer.svg'
import {
  APPLY_IDO_LINK,
  CAREERS_LINK,
  FAQ_LINK,
  FORUM_LINK,
  INCUBATOR_LINK,
  OCCAMX_APP_LINK,
  OCCAMX_DASHBOARD_LINK,
  RAZER_APP_LINK,
  SOCIAL_LINKS,
  SUPPORT_LINK,
  VC_DAO,
} from 'utils/links'
import Partners from './Partners'

export default function Footer() {
  return (
    <div className="bg-footerBg bg-cover bg-center">
      <Partners />
      <div className={`${LAYOUT_STYLES}`}>
        <div
          className="
          dxs:grid  
          dxs:grid-cols-[10.6rem,auto] dxs:grid-rows-[2.8rem,auto] dxs:gap-x-[5.25rem]
          ds:grid-cols-[14.05rem,auto] ds:grid-rows-[3.55rem,auto]  
          dm:gap-x-[12.2rem]
          dl:gap-x-[14.25rem] 
          "
        >
          {/* 1 */}
          <div className="hidden dxs:block">
            <OccamFooterrIcon className="relative top-[0.05rem] h-full w-fit border-b border-b-[#333333] t:w-[10.6rem] ds:w-[14.05rem]" />
          </div>
          {/* 2 */}
          <div className="relative top-[0.05rem] flex flex-wrap items-center justify-between dxs:space-x-[2rem]">
            <FooterLink
              secondType
              title="Razer Launchpad"
              link={RAZER_APP_LINK}
            />
            <FooterLink
              secondType
              title={
                <span>
                  OccamX<span className="hidden ml:inline"> DEX</span>
                </span>
              }
              link={OCCAMX_APP_LINK}
            />
            <FooterLink secondType title="VC DAO" link={VC_DAO} />
            <FooterLink secondType title="Incubator" link={INCUBATOR_LINK} />
          </div>
          <div className="col-span-2 h-[0.05rem] bg-[#333333]/10" />
          {/* 3 */}
          <div />
          {/* 4 */}
          <div
            className="
              mt-[2rem] flex flex-col-reverse items-start justify-between
              dxs:mt-[2.8rem] dxs:flex-row

            "
          >
            <div
              className="
                grid w-full  grid-cols-[2.9fr,2.5fr,auto] gap-x-[1rem] 
                gap-y-[1.4rem]
                dxs:w-[21.5rem] dxs:grid-cols-3 dxs:gap-[2rem]
                ds:w-[20rem]
                dm:w-[21.5rem]
                dl:w-[26rem]
              "
            >
              {FOOTER_LINKS.map((item, i) => (
                <FooterLink {...item} key={`footer-links-${i}`} />
              ))}
            </div>
            <div className="flex w-full flex-col dxs:w-auto">
              <div
                className="
                  relative top-[-0.6rem] mb-[2rem] flex justify-between space-x-[1rem]
                  dxs:mb-0 dxs:justify-start
                "
              >
                {SOCIAL_LINKS.map((item, i) => (
                  <SocialLink
                    key={`social-links-${i}`}
                    icon={item.name}
                    url={item.link}
                    className="h-[1rem] w-[1rem] fill-[#333333]/30 group-hover:fill-veryBlue"
                    wrapClassName="
                      group flex items-center justify-center w-[2.4rem] h-[2.4rem]
                      bg-[#76AAE7]/0 border-[#76AAE7]/0
                      hover:bg-white hover:border-[#76AAE7]/10 hover:bg-[#76AAE7]/30
                      rounded-full transition-opacity
                    "
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[1.55rem] pb-[0.9rem] text-[0.8rem] text-grayTextSecond opacity-60 dxs:text-right">
          © 2020-2022 Occam Inc.
        </div>
      </div>
    </div>
  )
}

type FooterLinkProps = {
  link: string
  title: string | ReactNode
  inner?: boolean
  secondType?: boolean
}
function FooterLink({ link, title, inner, secondType }: FooterLinkProps) {
  const linkCn = !secondType
    ? 'transition-opacity text-grayTextSecondt opacity-70 hover:opacity-100 text-[0.95rem] dxs:text-[0.8rem] block font-light hover:text-veryBlue'
    : 'transition-opacity text-[0.8rem] ml:text-[1rem] w-fit font-medium border-b border-b-[#333333] text-center h-full pt-[0.6rem] hover:text-veryBlue mr-[1rem] last:mr-0'

  return inner ? (
    <Link className={linkCn} to={{ pathname: link }}>
      {title}
    </Link>
  ) : (
    <a className={linkCn} href={link} target="_blank" rel="noreferrer">
      {title}
    </a>
  )
}

const FOOTER_LINKS = [
  {
    link: VC_DAO,
    title: 'VC DAO',
  },
  {
    link: '/pdf/occam_deck.pdf',
    title: 'Deck',
  },
  {
    link: '/pdf/occamrazer_wp_v1.pdf',
    title: 'Whitepaper',
  },
  {
    link: 'https://drive.google.com/drive/folders/1KJRfZHf966IkpoMvsIVUOBWCsLZ15ERV?usp=sharing',
    title: 'Media Pack',
  },
  {
    link: FAQ_LINK,
    title: 'FAQ',
  },
  {
    link: APPLY_IDO_LINK,
    title: 'Apply for IDO',
  },
  {
    link: SUPPORT_LINK,
    title: 'Support',
  },
  {
    link: CAREERS_LINK,
    title: 'Careers',
  },
  {
    link: FORUM_LINK,
    title: 'Forum',
  },
  {
    link: OCCAMX_DASHBOARD_LINK,
    title: 'OCX Dashboard',
  },
]
